import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TituloAbaService {

  constructor(private titleService: Title, private meta:Meta) { }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(`${newTitle} | azulzinha da CAIXA`);
  }
  public setMeta(name: string, content: string) {
    this.meta.removeTag( `name='${name}'` )
    this.meta.addTag(
      { 
        name: name, 
        content: content 
      }
    );
  }
}
