<header class="header">
  <div class="linha-header header">
    <div class="logo-area">
      <picture>
        <a routerLink="/"><img class="logo-azulzinha" [lazyLoad]="'/assets/img/azulzinha-logo-nobg.png'" /></a>
      </picture>
    </div>
    <div class="menu-area-mobile mobile-only">
      <div class="logo-caixa-mobile">
        <picture>
          <img [lazyLoad]="'/assets/img/caixa_negativa.png'" class="caixa-logo" />
        </picture>
      </div>
      <div class="combo-menu-area" (click)="toggleMobileMenu()">
        <div class="hamburger-menu">
          <div class="linha"></div>
          <div class="linha"></div>
          <div class="linha"></div>
        </div>
      </div>

      <div *ngIf="openMobileMenu" class="menu-mobile-aberto overflow-auto">
        <div class="menu-mobile-content">
          <div class="botao-fechar" (click)="toggleMobileMenu()">X</div>
          <div class="menu-mobile-item has-sub-mobile">
            <a (click)="toggleSolucoesMenu()">Soluções</a>
            <div *ngIf="openSolucoesMenu" class="sub-menu-list-mobile">
              <div class="sub-menu-item">
                <a (click)="toggleMobileMenu()" routerLink="/azulzinha-caixa-tem">CAIXA Tem</a>
              </div>
              <div class="sub-menu-item">
                <a (click)="toggleMobileMenu()" routerLink="/maquininhas">Maquininhas</a>
              </div>
              <div class="sub-menu-item has-sub-sub-mobile mt-4">
                <a (click)="toggleMobileMenu()" routerLink="/vendas-online">Vendas Online</a>
              </div>
            </div>
          </div>
          <div class="menu-mobile-item has-sub-mobile">
            <a (click)="toggleGestaoMenu()">Gestão do seu negócio</a>
            <div *ngIf="openGestaoMenu" class="sub-menu-list-mobile">
              <div class="sub-menu-item">
                <a (click)="toggleMobileMenu()" routerLink="/gestao">Gestão do seu negócio</a>
              </div>
              <div class="sub-menu-item mt-4">
                <a (click)="toggleMobileMenu()" routerLink="/app">App azulzinha</a>
              </div>
              <div class="sub-menu-item mt-4">
                <a (click)="toggleMobileMenu()" routerLink="/portal">Portal azulzinha</a>
              </div>
            </div>
          </div>
          <div class="menu-mobile-item has-sub-mobile">
            <a (click)="toggleServicosMenu()">Serviços</a>
            <div *ngIf="openServicosMenu" class="sub-menu-list-mobile">
              <div class="sub-menu-item">
                <a (click)="toggleMobileMenu()" routerLink="/antecipa">Antecipação de vendas</a>
              </div>
              <div class="sub-menu-item mt-4">
                <a (click)="toggleMobileMenu()" routerLink="/bandeiras-vouchers">Bandeiras e Vouchers</a>
              </div>
              <div class="sub-menu-item mt-4">
                <a (click)="toggleMobileMenu()" routerLink="/link-pagamento">Link de Pagamento</a>
              </div>
              <div class="sub-menu-item mt-4">
                <a (click)="toggleMobileMenu()" routerLink="/lotericas">Lotéricas</a>
              </div>
              <div class="sub-menu-item mt-4">
                <a (click)="toggleMobileMenu()" routerLink="/pagamento-parcial">Pagamento Parcial</a>
              </div>
              <div class="sub-menu-item mt-4">
                <a (click)="toggleMobileMenu()" routerLink="/whatsapp-pay">Pagamentos por WhatsApp</a>
              </div>
              <div class="sub-menu-item mt-4">
                <a (click)="toggleMobileMenu()" href="/azulzinhapix">Pix</a>
              </div>
              <div class="sub-menu-item mt-4">
                <a (click)="toggleMobileMenu()" routerLink="/recarga">Recarga</a>
              </div>
            </div>
          </div>
          <div class="menu-mobile-item">
            <a (click)="toggleMobileMenu()" routerLink="/ajuda">Ajuda</a>
          </div>

          <div class="menu-mobile-item">
            <a href="https://portal.azulzinhadacaixa.com.br/" target="_blank">Portal</a>
          </div>
          <div class="menu-mobile-item menu-mobile-destaque">
            <a (click)="onNavigation('gtm-solicitar')" (click)="toggleMobileMenu()" routerLink="/peca-azulzinha">Peça já
              a azulzinha</a>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-area desk-only">
      <div class="fiserv-logo">
        <div class="fiserv-logo-img">
          <picture>
            <img class="img-logo-margin-left" [lazyLoad]="'/assets/img/caixa_negativa.png'" />
          </picture>
        </div>
      </div>
      <div class="menu-item">
        <div class="list-menu-itens">
          <div class="btn btn-outline-secondary btn-fale-conosco">
            <a>Soluções</a>
            <div class="sub-menu" style="width: max-content">
              <div class="sub-menu-list">
                <div class="sub-menu-item has-sub">
                  <a routerLink="/azulzinha-caixa-tem">CAIXA Tem</a>
                </div>
                <div class="sub-menu-item has-sub">
                  <a routerLink="/maquininhas">Maquininhas</a>
                </div>
                <div class="sub-menu-item has-sub">
                  <a routerLink="/vendas-online">Vendas Online</a>
                </div>

              </div>
            </div>
          </div>
          <div class="btn btn-outline-secondary btn-fale-conosco">
            <a routerLink="/gestao">Gestão do seu negócio</a>
            <div class="sub-menu" style="width: 100%">
              <div class="sub-menu-list">
                <div class="sub-menu-item">
                  <a routerLink="/app">App da azulzinha </a>
                </div>
                <div class="sub-menu-item">
                  <a routerLink="/portal">Portal da azulzinha </a>
                </div>
              </div>
            </div>
          </div>
          <div class="btn btn-outline-secondary btn-fale-conosco">
            <a>Serviços</a>
            <div class="sub-menu" style="width: calc(100% + 11rem)">
              <div class="sub-menu-list">
                <div class="sub-menu-item">
                  <a routerLink="/antecipa">Antecipação de vendas</a>
                </div>
                <div class="sub-menu-item">
                  <a routerLink="/bandeiras-vouchers">Bandeiras e Vouchers</a>
                </div>
                <div class="sub-menu-item">
                  <a routerLink="/link-pagamento">Link de Pagamento</a>
                </div>
                <div class="sub-menu-item">
                  <a routerLink="/lotericas">Lotéricas</a>
                </div>
                <div class="sub-menu-item">
                  <a routerLink="/pagamento-parcial">Pagamento Parcial</a>
                </div>
                <div class="sub-menu-item">
                  <a routerLink="/whatsapp-pay">Pagamentos por WhatsApp</a>
                </div>
                <div class="sub-menu-item">
                  <a routerLink="/azulzinhapix">Pix</a>
                </div>
                <div class="sub-menu-item">
                  <a routerLink="/recarga">Recarga</a>
                </div>
              </div>
            </div>
          </div>
          <div class="btn btn-outline-secondary btn-fale-conosco">
            <a routerLink="/ajuda">Ajuda</a>
          </div>

          <div class="btn btn-outline-secondary btn-fale-conosco">
            <a href="https://portal.azulzinhadacaixa.com.br/" target="_blank">Portal</a>
          </div>
        </div>
        <div>
          <a (click)="onNavigation('gtm-solicitar')" class="btn btn-primary btn-big btn-portal"
            routerLink="/peca-azulzinha">Peça já a azulzinha</a>
        </div>
      </div>
    </div>
  </div>
</header>